<template>
    <div class="float-menu">
        <img v-if="showTop" class="back-top" src="@/assets/images/index/back-top.png" @click="backTop" />
        <img v-if="kfUrl" class="kefu" src="@/assets/images/index/kefu.png" @click="tokefu" />
		<img v-if="kfUrl2" class="kefu2" src="@/assets/images/index/whatsapp.png" @click="tokefu2" />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                kfUrl: '',
				kfUrl2: '',
                showTop: false
            }
        },
        created() {
            this.getKfUrl()
        },
        mounted() {
            window.addEventListener('scroll', () => {
                let scroll_height = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0
                if(scroll_height > 20) {
                    this.showTop = true
                } else {
                    this.showTop = false
                }
            })
        },
        methods: {
            getKfUrl() {
                this.$api.setting({
                    slug: 'kf_url,kf_url2'
                },false,300000).then(res => {
                    this.kfUrl = res.data.kf_url || ''
					this.kfUrl2 = res.data.kf_url2 || ''
                })
            },
            
            tokefu() {
                if(this.kfUrl) {
                    window.open(this.kfUrl)
                }
            },
			
			tokefu2() {
				if(this.kfUrl2) {
				    window.open(this.kfUrl2)
				}
			},
            
            backTop() {
                window.scrollTo(0, 0)
            }
        }
    }
</script>

<style lang="scss" scoped>
    // PC
    @media only screen and (min-width: 1024px) {
        .float-menu {
            position: fixed;
            bottom: 10rem;
            right: $box-padding;
            z-index: 80;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .back-top, 
            .kefu, 
			.kefu2 {
                width: 5rem;
                height: 5rem;
                cursor: pointer;
            }
            
            .kefu {
                margin-top: 1.2rem;
            }
			
			.kefu2 {
				margin-top: 1.2rem;
			}
        }
    }
    
    // 手机
    @media only screen and (max-width: 1024px) {
        .float-menu {
            position: fixed;
            bottom: 10rem;
            right: $h5-box-padding;
            z-index: 80;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .back-top, 
            .kefu,
			.kefu2 {
                width: 5rem;
                height: 5rem;
                cursor: pointer;
            }
            
            .kefu {
                margin-top: 1.2rem;
            }
			
			.kefu2 {
				margin-top: 1.2rem;
			}
        }
    }
</style>